import { UniSupportApiCS } from '@unikey/unikey-commons/release/csupp'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'pdk';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://pdk-stg-dealer-api.unikey.com/v2/nonces' };
export const supportApi: UniSupportApiCS = new UniSupportApiCS('https://pdk-stg-support-api.unikey.com', 'api/v1', '', { noNonce: true, enableLogging: true, ...commNonceEndpoint });
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', 'a6068e26-a8c1-49b9-9349-bf6386da4d15', '4tLFg2myEAvSjhxUhCmvhw+pJvdy+on0XdhW8YR1XJpE+VEvplYOj9fV/2i0wAPA+xEVJZ+KUVFbQcGHUT1NsQ==', { enableLogging: false, ...commNonceEndpoint });

export const oidcScope: string = 'openid email profile comm.support.api identity.api offline_access';
export const oidcClientId: string = 'a6068e26-a8c1-49b9-9349-bf6386da4d15';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://pdk-stg-support.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = '4tLFg2myEAvSjhxUhCmvhw+pJvdy+on0XdhW8YR1XJpE+VEvplYOj9fV/2i0wAPA+xEVJZ+KUVFbQcGHUT1NsQ==';
export const oidcPartnerBrandId: string = 'f8b70bea-396d-4c17-885e-ea44d5ba4999';
export const oidcPartnerBrandCode: string = 'PDK';
export const oidcSilentRedirectUri: string = 'https://pdk-stg-support.unikey.com/#/renew';
export const oidcForgotPasswordRedirect: string = '';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.prodatakey.com/support';

export const appInsightsInstrumentationKey: string = 'baf24534-1e66-4445-b1e5-48c5e7701d69';
export const mockAuth: string = '';

export const buildId: string = '36429';
export const releaseId: string = '6208';
export const cspVersion: string = '3.10.0';
export const commonsVersion: string = '8.0.0-pre-1226';

export const desiredLocales: string = 'en,es,ja,fr,pt';
